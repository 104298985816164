export default class floorPlanDetails {
    width: number;
    height: number;
    tileSideLenght: number;

    constructor(width: number, height: number, tileSideLenght: number){
        this.width = width;
        this.height = height;
        this.tileSideLenght = tileSideLenght;
    }
}