var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-simulation","title":"Simulation configuration"},on:{"ok":function($event){return _vm.alert(1)}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [(_vm.infoCard)?_c('b-button',{staticClass:"float-right",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$bvModal.hide('modal-simulation')}}},[_vm._v("Cancel")]):_vm._e(),(_vm.infoCard)?_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":_vm.submit}},[_vm._v("Start simulation")]):_vm._e(),(!_vm.infoCard)?_c('b-button',{staticClass:"float-right",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.reset()}}},[_vm._v("Reset simulation")]):_vm._e(),(!_vm.infoCard)?_c('b-button',{staticClass:"float-right",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$bvModal.hide('modal-simulation')}}},[_vm._v("Close")]):_vm._e()]},proxy:true}])},[(_vm.infoCard)?_c('div',[_c('v-card-text',[_c('v-form',{model:{value:(_vm.isValid),callback:function ($$v) {_vm.isValid=$$v},expression:"isValid"}},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Date","required":"","rules":[
                  function (v) { return !!v || 'Required'; },
                  function (v) { return /^\d{4}-\d{2}-\d{2}$/.test(v) ||
                    'Enter Date in the correct format: yyyy-mm-dd'; } ]},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Time","required":"","hint":"HH:mm:ss","rules":[
                  function (v) { return !!v || 'Required'; },
                  function (v) { return /^\d{2}:\d{2}:\d{2}$/.test(v) ||
                    'Enter time in the correct format: HH:mm:ss'; } ]},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}})],1)],1),_c('b-form-checkbox',{model:{value:(_vm.instantSimulation),callback:function ($$v) {_vm.instantSimulation=$$v},expression:"instantSimulation"}},[_vm._v("Instant Simulation")]),_c('v-text-field',{attrs:{"label":"Relative Time","hint":"1.0","rules":[
              function (v) { return !!v || 'Required'; },
              function (v) { return /^\d*\.\d{0,2}$/.test(v) || 'Enter a number with max 2 decimal places'; } ],"required":""},model:{value:(_vm.relativeTime),callback:function ($$v) {_vm.relativeTime=$$v},expression:"relativeTime"}}),_c('v-text-field',{attrs:{"label":"Seed","hint":"3","rules":[
              function (v) { return !!v || 'Required'; },
              function (v) { return /[123]/.test(v) || 'Enter either 0, 1 or 2'; } ]},model:{value:(_vm.seed),callback:function ($$v) {_vm.seed=$$v},expression:"seed"}}),_c('b-form-checkbox',{model:{value:(_vm.mqttOutput),callback:function ($$v) {_vm.mqttOutput=$$v},expression:"mqttOutput"}},[_vm._v("Mqtt Output")]),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"label":"Mqtt Host","required":"","rules":[function (v) { return !!v || 'Required'; }]},model:{value:(_vm.mqttHost),callback:function ($$v) {_vm.mqttHost=$$v},expression:"mqttHost"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Mqtt Port","required":"","rules":[function (v) { return !!v || 'Required'; }]},model:{value:(_vm.mqttPort),callback:function ($$v) {_vm.mqttPort=$$v},expression:"mqttPort"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"label":"Root Topic","required":"","rules":[function (v) { return !!v || 'Required'; }]},model:{value:(_vm.rootTopic),callback:function ($$v) {_vm.rootTopic=$$v},expression:"rootTopic"}})],1)],1)],1)],1)],1):_vm._e(),(!_vm.infoCard)?_c('div',[_c('b-form-textarea',{staticClass:"editor",attrs:{"value":_vm.simulationInfo,"plaintext":""}})],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }